import React from 'react'
import { ServicesContainer, ServicesH1, ServicesWrapper, ServicesCard, ServicesIcon, ServicesH2, ServicesP } from './ServicesElements'
import Icon1 from '../../images/ux.png'
import Icon2 from '../../images/user-interface.png'
import Icon3 from '../../images/internet-of-things.png'
const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>
                Our Services
            </ServicesH1>
            <ServicesWrapper>
        <ServicesCard>
            <ServicesIcon src={Icon1}/>
            <ServicesH2>
               Website Development
            </ServicesH2>
            <ServicesP>
                We create captivating websites that amplify your brand presence. Our team leverages cutting-edge tech and design principles for stunning, responsive sites that drive engagement and conversions.
            </ServicesP>
            </ServicesCard>

            <ServicesCard>
            <ServicesIcon src={Icon2}/>
            <ServicesH2>
            App Development
            </ServicesH2>
            <ServicesP>
                Turn visions to immersive apps. Our end-to-end development ensures seamless user experience. Native, hybrid, or cross-platform, we mesmerize and engage users with high-quality solutions.
            </ServicesP>
            </ServicesCard>

            <ServicesCard>
            <ServicesIcon src={Icon3}/>
            <ServicesH2>
              Tech Solutions
            </ServicesH2>
            <ServicesP>
                Tailored tech solutions for streamlined efficiency and productivity. From databases to cloud tech, we optimize operations and fuel growth. Collaborative and innovative approach.
            </ServicesP>
            </ServicesCard>
            </ServicesWrapper>
        </ServicesContainer>

    )
}

export default Services
