import React, {useState} from 'react'
import Video from '../../videos/video.mp4'
import {HeroBg, HeroContainer, VideoBg, HeroContent, HeroH1, HeroP, HeroBtnWrapper, ArrowForward, ArrowRight} from './HeroElements'
import { Button } from '../ButtonElement'

const HeroSection = () => {
    const [hover, setHover] = useState(false)

    const onHover =() => {
        setHover(!hover);
    }
    return (
        <HeroContainer>
            <HeroBg>
                <VideoBg autoPlay loop muted src={Video} type='video/mp4'/>
            </HeroBg>
            <HeroContent>
                <HeroH1>
                    - bring your tech
                    ideas to
                    reality
                </HeroH1>
                <HeroP>
                    Tech innovators, crafting cutting-edge solutions with expertise, passion, and a relentless drive for digital excellence.

<HeroBtnWrapper>
    <Button to="contactUs" onMouseEnter={onHover} onMouseLeave={onHover}>
        Get a Quote {hover ? <ArrowForward />: <ArrowRight/>}
    </Button>
</HeroBtnWrapper>
                </HeroP>
            </HeroContent>
        </HeroContainer>
    )
}

export default HeroSection
