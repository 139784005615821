import React from 'react'
import { Button } from '../ButtonElement'
import { BtnWrap, Column2, ImgWrap, TopLine, InfoContainer, InfoRow, InfoWrapper, Column1, TextWrapper, Heading, Subtitle, Img } from './InfoElements'

const InfoSection = ({dark, dark2, lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt}) => {
    let buttonLink = "tel:+8801707078906";
    return (
        <>
           <InfoContainer lightBg={lightBg} id={id}>
               <InfoWrapper>
                   <InfoRow imgStart={imgStart}>
                       <Column1>
                       <TextWrapper>
                          <TopLine>
                              {topLine}
                          </TopLine>
                          <Heading lightText={lightText}>
                              {headline}
                              </Heading>
                              <Subtitle darkText={darkText}>
                                  {description}
                              </Subtitle>
                              <BtnWrap>
                                  <a href={buttonLink}>
                                  <Button to='contactUs'
                                  >{buttonLabel}</Button>
                                  </a>
                              </BtnWrap>
                       </TextWrapper>
                       </Column1>
                       <Column2>
                       <ImgWrap>
                        <Img src={img} alt={alt}/>
                       </ImgWrap>
                       </Column2>
                   </InfoRow>
               </InfoWrapper>
               </InfoContainer>
        </>
    )
}

export default InfoSection
